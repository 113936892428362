import React from "react"
import Block from "../components/block"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubHeader from "../components/subHeader"
import ContactFormSection from "../components/contactFormSection"
import InstagramSection from "../components/instagramSection"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import CallToAction from "../components/callToAction"

// image placeholder
import image from "../images/trial.jpg"

const Team = ({ data }) => {
  const node = data?.allWpPage?.edges[0]?.node
  const block = node?.trainingCourses?.block
  const cto = node?.aboutUs?.cto

  //   call to action
  const ctoHeader = cto?.headercto
  const ctoContent = cto?.content
  const ctoButton = cto?.button.text
  const ctoButtonLink = cto?.button.link
  const ctoImage = getSrc(cto.image?.localFile)

  return (
    <Layout>
      <Seo title={node.headerPage.pageTitle} />
      <SubHeader
        title={node.headerPage.pageTitle}
        image={getSrc(node.headerPage.backgroundImage.localFile)}
      />
      {block.map((item, index) => {
        return (
          <Block
            key={index}
            title={item.headerb}
            content={item.content}
            button1={item.button?.text}
            button1_link={item.button?.link}
            button2={item.buttonSecondary?.text}
            button2_link={item.buttonSecondary?.link}
            img={getSrc(item.image.localFile)}
            imgAlt={item.image?.altText}
            inverted={item?.inverted}
          />
        )
      })}

      <CallToAction
        style={{ marginTop: 120 }}
        header={ctoHeader}
        content={ctoContent}
        button={ctoButton}
        button_link={ctoButtonLink}
        img={ctoImage || image}
      />

      <ContactFormSection />
      <InstagramSection />
    </Layout>
  )
}

export default Team

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDozNjY=" } }) {
      edges {
        node {
          headerPage {
            pageTitle
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          trainingCourses {
            block {
              content
              fieldGroupName
              headerb
              inverted
              button {
                text
                link
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
          aboutUs {
            cto {
              headercto
              content
              button {
                text
                link
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
